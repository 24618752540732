var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.movement
    ? _c(
        "div",
        { ref: "printableContent", staticClass: "movement-summary" },
        [
          _c(
            "b-button",
            {
              staticClass: "my-1",
              attrs: { variant: "primary" },
              on: { click: _vm.printMovement },
            },
            [_vm._v(" Imprimir Romaneio ")]
          ),
          _c(
            "b-card-group",
            { attrs: { deck: "" } },
            [
              _c("b-card", { attrs: { header: "Informações básicas" } }, [
                _c("p", [
                  _c("strong", [_vm._v("ID do Romaneio:")]),
                  _vm._v(" " + _vm._s(_vm.movement.id) + " "),
                ]),
                _c("p", [
                  _c("strong", [_vm._v("Criado em:")]),
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("datePT")(_vm.movement.created_at, true)) +
                      " "
                  ),
                ]),
                _c("p", [
                  _c("strong", [_vm._v("Criado Por:")]),
                  _vm._v(" " + _vm._s(_vm.movement.user.full_name) + " "),
                ]),
                _c("p", [
                  _c("strong", [_vm._v("Status:")]),
                  _vm._v(" " + _vm._s(_vm.setOrderStep(_vm.movement))),
                ]),
                _c("p", [
                  _c("strong", [_vm._v("Total:")]),
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("toCurrency")(_vm.movement.total_value)) +
                      " "
                  ),
                ]),
              ]),
              _vm.movement.bank
                ? _c("b-card", { attrs: { header: "Banco" } }, [
                    _c("p", [
                      _c("strong", [_vm._v("Banco:")]),
                      _vm._v(" " + _vm._s(_vm.movement.bank.name) + " "),
                    ]),
                    _c("p", [
                      _c("strong", [_vm._v("Conta:")]),
                      _vm._v(" " + _vm._s(_vm.movement.bank.account_number)),
                    ]),
                    _c("p", [
                      _c("strong", [_vm._v("Agência:")]),
                      _vm._v(" " + _vm._s(_vm.movement.bank.agency)),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-card",
            { attrs: { "no-body": "" } },
            [
              _c("b-table", {
                staticClass: "mb-0",
                attrs: {
                  items: _vm.movement.items,
                  fields: _vm.fields,
                  bordered: "",
                  striped: "",
                  hover: "",
                  responsive: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(pc_id)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            " " + _vm._s(data.item.purchaseOrder.id) + " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(doc)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(data.item.purchaseOrder.document_number) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(description)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(data.item.purchaseOrder.description) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(installment_due)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(data.item.purchaseOrder.installment_due) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(installments)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(data.item.purchaseOrder.installments) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(supplier)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                data.item.purchaseOrder.supplier.company_name
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(value)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("toCurrency")(data.item.value)) +
                              " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3213992717
                ),
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }