<template>
  <div class="movement-summary" v-if="movement" ref="printableContent">
    <b-button variant="primary" class="my-1" @click="printMovement">
      Imprimir Romaneio
    </b-button>
    <b-card-group deck>
      <b-card header="Informações básicas">
        <p>
          <strong>ID do Romaneio:</strong>
          {{ movement.id }}
        </p>
        <p>
          <strong>Criado em:</strong>
          {{ movement.created_at | datePT(true) }}
        </p>
        <p>
          <strong>Criado Por:</strong>
          {{ movement.user.full_name }}
        </p>
        <p><strong>Status:</strong> {{ setOrderStep(movement) }}</p>
        <p>
          <strong>Total:</strong>
          {{ movement.total_value | toCurrency }}
        </p>
      </b-card>

      <b-card v-if="movement.bank" header="Banco">
        <p>
          <strong>Banco:</strong>
          {{ movement.bank.name }}
        </p>
        <p><strong>Conta:</strong> {{ movement.bank.account_number }}</p>
        <p><strong>Agência:</strong> {{ movement.bank.agency }}</p>
      </b-card>
    </b-card-group>

    <b-card no-body>
      <b-table
        :items="movement.items"
        :fields="fields"
        bordered
        striped
        hover
        responsive
        class="mb-0"
      >
        <template #cell(pc_id)="data">
          {{ data.item.purchaseOrder.id }}
        </template>

        <template #cell(doc)="data">
          {{ data.item.purchaseOrder.document_number }}
        </template>

        <template #cell(description)="data">
          {{ data.item.purchaseOrder.description }}
        </template>

        <template #cell(installment_due)="data">
          {{ data.item.purchaseOrder.installment_due }}
        </template>

        <template #cell(installments)="data">
          {{ data.item.purchaseOrder.installments }}
        </template>

        <template #cell(supplier)="data">
          {{ data.item.purchaseOrder.supplier.company_name }}
        </template>

        <template #cell(value)="data">
          {{ data.item.value | toCurrency }}
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardGroup,
  BButton,
} from "bootstrap-vue";

import moment from "moment";

export default {
  components: {
    BCard,
    BTable,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BCardGroup,
    BButton,
  },
  data() {
    return {
      movement: null,
      fields: [
        { key: "pc_id", label: "ID PC" },
        { key: "doc", label: "Doc" },
        { key: "description", label: "Descrição" },
        { key: "installments", label: "Parcelas" },
        { key: "installment_due", label: "Vencimento" },
        { key: "supplier", label: "Fornecedor" },
        { key: "value", label: "Valor" },
      ],
    };
  },
  async mounted() {
    const uuid = this.$route.params.uuid;

    try {
      const response = await this.$store.dispatch(
        "OrderPurchase/getMovementByUuid",
        uuid
      );
      this.movement = response;
    } catch (error) {
      console.error("Erro ao carregar movimento:", error);
    }
  },
  methods: {
    printMovement() {
      const content = this.$refs.printableContent.innerHTML;

      const printWindow = window.open("", "_blank");

      printWindow.document.write(`
        <html>
          <head>
            <title>Romaneio #${this.movement.id}</title>
            <style>
              body {
                font-family: Arial, sans-serif;
              }
              .table {
                width: 100%;
                border-collapse: collapse;
              }
              .table th, .table td {
                border: 1px solid #ddd;
                padding: 8px;
              }
              .table th {
                background-color: #f2f2f2;
                text-align: left;
              }
            </style>
          </head>
          <body onload="window.print(); window.close();">
            <div class="container">
              ${content}
            </div>
          </body>
        </html>
      `);

      printWindow.document.close();
    },
    formatDate(value) {
      if (!value) return "";
      return moment(value).format("DD/MM/YYYY HH:mm:ss");
    },
    setOrderStep(item) {
      const oldStep = item.oldStepRelation ? item.oldStepRelation.name : "";
      const newStep = item.newStepRelation
        ? item.newStepRelation.name
        : "Pagamento";
      return `${oldStep} >> ${newStep}`;
    },
  },
};
</script>

<style scoped>
p {
  margin-bottom: 5px;
}

.in-words {
  font-style: italic;
  color: #666;
}
</style>
